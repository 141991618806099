@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		font-family: "Poppins", sans-serif;
		background: #fdf7f5;
	}
}
